export const COUNTRY_CODES = {
    Germany: "DE",
    Deutschland: "DE",
    Österreich: "AT",
    Italien: "IT",
    Slowenien: "SI",
    Luxemburg: "LU",
    Belgien: "BE",
    Frankreich: "FR",
    Schweden: "SE",
    Polen: "PL",
    Niederlande: "NL",
    Schweiz: "CH",
    Ungarn: "HU",
};
export const COUNTRY_NAMES = Object.fromEntries(Object.entries(COUNTRY_CODES).map(([key, value]) => [value, key]));
export const COUNTRY_CODES_EN = {
    Germany: "DE",
    Austria: "AT",
    Italy: "IT",
    Slovenia: "SI",
    Luxembourg: "LU",
    Belgium: "BE",
    France: "FR",
    Sweden: "SE",
    Poland: "PL",
    Netherlands: "NL",
    Switzerland: "CH",
    Hungary: "HU",
};
export const COUNTRY_NAMES_EN = Object.fromEntries(Object.entries(COUNTRY_CODES_EN).map(([key, value]) => [value, key]));
export const findCountryCodeByName = (country) => {
    if (Object.keys(COUNTRY_CODES).includes(country)) {
        return COUNTRY_CODES[country];
    }
    if (Object.keys(COUNTRY_CODES_EN).includes(country)) {
        return COUNTRY_CODES_EN[country];
    }
    return null;
};
