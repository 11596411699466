import { erpQuery } from "./erpClient";
import Dataloader from "dataloader";
import { SalesInvoice } from "../types/ERPSalesInvoice";
import { AuthContextType, AuthType } from "./AuthProvider";
import { findCountryCodeByName } from "shipping";

export const OPEN_ORDERS_FILTER = [
  ["Sales Order", "status", "!=", "Cancelled"],
  ["Sales Order", "status", "!=", "Draft"],
  ["Sales Order", "status", "!=", "Closed"],
  ["Sales Order", "status", "!=", "Completed"],
  ["Sales Order", "status", "!=", "To Bill"],
];
const ALL_ORDERS_FILTER = [
  ["Sales Order", "status", "!=", "Cancelled"],
  ["Sales Order", "status", "!=", "Draft"],
];

const OPEN_PURCHASEORDERS_FILTER = [
  ["Purchase Order", "status", "!=", "Cancelled"],
  ["Purchase Order", "status", "!=", "Draft"],
  ["Purchase Order", "status", "!=", "Closed"],
  ["Purchase Order", "status", "!=", "Completed"],
  ["Purchase Order", "status", "!=", "To Bill"],
];
const ALL_PURCHASEORDERS_FILTER = [
  ["Purchase Order", "status", "!=", "Cancelled"],
  ["Purchase Order", "status", "!=", "Draft"],
];

export const fetchOrders = async ({
  showOnlyOpenOrders = true,
  rowsPerPage = 10,
  page = 0,
  searchQuery = "",
  authContext,
}: {
  showOnlyOpenOrders: boolean;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
  authContext: AuthContextType;
}): Promise<
  [
    {
      name: string;
      owner: string;
      delivery_date: string;
      status: string;
      delivery_status: string;
      shipping_address_name: string;
      customer: string;
      customer_group: string;
      shipping_address: string;
      deliverytype: string;
      in_process_by: string;
    }
  ]
> => {
  let filter = [];
  if (showOnlyOpenOrders) filter = [...OPEN_ORDERS_FILTER];
  else filter = [...ALL_ORDERS_FILTER];
  if (searchQuery != "")
    filter.push(["Sales Order", "customer_name", "like", `%${searchQuery}%`]);

  const orderListParams = new URLSearchParams({
    doctype: "Sales Order",
    fields: JSON.stringify([
      "name",
      "owner",
      "delivery_date",
      "status",
      "delivery_status",
      "shipping_address_name",
      "customer",
      "customer_group",
      "shipping_address",
      "deliverytype",
      "in_process_by",
    ]),
    filters: JSON.stringify(filter),
    page_length: rowsPerPage.toString(),
    start: (rowsPerPage * page).toString(),
    order_by:
      "`tabSales Order`.`delivery_date` " +
      (showOnlyOpenOrders ? "asc" : "desc"),
  });
  let orderListQuery = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: orderListParams.toString(),
  });
  if (!orderListQuery) throw "Order query unsuccessful";

  let orderList = [];
  orderListQuery.message.values.map((row) =>
    orderList.push(
      Object.fromEntries(
        row.map((value, index) => [orderListQuery.message.keys[index], value])
      )
    )
  );

  let salesOrderItemFilters = [];
  orderList.forEach((salesOrder) => {
    salesOrderItemFilters.push(["Sales Order", "name", "=", salesOrder.name]);
  });

  const salesOrderItemsParams = new URLSearchParams({
    doctype: "Sales Order",
    fields: JSON.stringify([
      "`tabSales Order Item`.`item_name`",
      "`tabSales Order Item`.`qty`",
      "`tabSales Order Item`.`uom`",
      "`tabSales Order Item`.`idx`",
      "`tabSales Order Item`.`parent`",
      "`tabSales Order Item`.`item_code`",
    ]),
    or_filters: JSON.stringify(salesOrderItemFilters),
  });

  await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: salesOrderItemsParams.toString(),
  }).then((res) => {
    res.message?.values
      .sort((item1, item2) => (item1[3] > item2[3] ? 1 : -1))
      .forEach((item) => {
        let orderId = orderList.findIndex((order) => order.name === item[4]);

        if (!orderList[orderId].items) orderList[orderId].items = [];

        orderList[orderId].items.push({
          item_name: item[0],
          qty: item[1],
          uom: item[2],
          item_code: item[5],
        });
      });
  });

  // the following query returns all connected Sales Invoices for the Sales Orders
  // unfortunately, not the Sales Invoices are connected with the Sales Order, but the sales invoice Items.
  let salesOrdersInvoiceFilters = [];
  orderList.forEach((salesOrder) => {
    salesOrdersInvoiceFilters.push([
      "Sales Invoice Item",
      "sales_order",
      "=",
      salesOrder.name,
    ]);
  });

  const salesOrderInvoiceParams = new URLSearchParams();
  salesOrderInvoiceParams.append("doctype", "Sales Invoice");
  salesOrderInvoiceParams.append(
    "fields",
    JSON.stringify([
      "`tabSales Invoice`.`name`",
      "`tabSales Invoice Item`.`sales_order`",
    ])
  );
  salesOrderInvoiceParams.append(
    "or_filters",
    JSON.stringify(salesOrdersInvoiceFilters)
  );
  salesOrderInvoiceParams.append("group_by", "`tabSales Invoice`.`name`");
  await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: salesOrderInvoiceParams.toString(),
  })
    .then((res) => {
      res.message.values?.forEach((invoice) => {
        let orderId = orderList.findIndex((order) => order.name === invoice[1]);

        if (!orderList[orderId].invoices) orderList[orderId].invoices = [];
        orderList[orderId].invoices.push(invoice[0]);
      });
    })
    .catch((error) => {});

  let commentsFilters = orderList.map((salesOrder) => [
    "Comment",
    "reference_name",
    "=",
    salesOrder.name,
  ]);
  const commentParams = new URLSearchParams({
    doctype: "Comment",
    fields: JSON.stringify([
      "`tabComment`.`content`",
      "`tabComment`.`creation`",
      "`tabComment`.`reference_name`",
    ]),
    or_filters: JSON.stringify(commentsFilters),
    filters: JSON.stringify([["Comment", "comment_type", "=", "Comment"]]),
  });
  await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: commentParams.toString(),
  })
    .then((res) => {
      res.message.values?.forEach((comment) => {
        let orderId = orderList.findIndex((order) => order.name === comment[2]);

        if (!orderList[orderId].comments) orderList[orderId].comments = [];

        orderList[orderId].comments.push({
          content: comment[0],
          creation: comment[1],
        });
      });
    })
    .catch((error) => {});

  return orderList;
};

export const fetchDepositReturns = async (
  authContext: AuthContextType,
  {
    showOnlyOpenReturns = true,
  }: {
    showOnlyOpenReturns: boolean;
  }
) => {
  let filter: [string, string, string, string][];
  if (showOnlyOpenReturns)
    filter = [["Deposit Return Order", "status", "=", "Open"]];
  else filter = [];

  const returnsListParams = new URLSearchParams({
    doctype: "Deposit Return Order",
    fields: JSON.stringify([
      "name",
      "customer",
      "creation",
      "status",
      "`tabDeposit Return Item`.`item`",
      "`tabDeposit Return Item`.`qty`",
    ]),
    filters: JSON.stringify(filter),
    order_by:
      "`tabDeposit Return Order`.`creation` " +
      (showOnlyOpenReturns ? "asc" : "desc"),
  });
  let returnsListQuery = (await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: returnsListParams.toString(),
  })) as {
    message: {
      values: [string, string, string, string, string, number][];
      keys: ["name", "customer", "creation", "status", "item", "qty"];
    };
  };
  if (!returnsListQuery) return false;

  let returnsList: {
    name: string;
    creation: string;
    customer: string;
    qty: number;
  }[] = [];
  returnsListQuery.message.values.map((row) =>
    returnsList.push(
      Object.fromEntries(
        row.map((value, index) => [returnsListQuery.message.keys[index], value])
      ) as {
        name: string;
        creation: string;
        customer: string;
        qty: number;
      }
    )
  );

  return returnsList;
};

export const fetchPurchaseOrders = async (
  authContext: AuthContextType,
  {
    showOnlyOpenOrders = true,
  }: {
    showOnlyOpenOrders: boolean;
  }
) => {
  let filter = [];
  if (showOnlyOpenOrders) filter = OPEN_PURCHASEORDERS_FILTER;
  else filter = ALL_PURCHASEORDERS_FILTER;

  const orderListParams = new URLSearchParams({
    doctype: "Purchase Order",
    fields: JSON.stringify([
      "name",
      "owner",
      "schedule_date",
      "status",
      "supplier",
    ]),
    filters: JSON.stringify(filter),
    order_by:
      "`tabPurchase Order`.`schedule_date` " +
      (showOnlyOpenOrders ? "asc" : "desc"),
  });
  let orderListQuery = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: orderListParams.toString(),
  });
  if (!orderListQuery) return false;

  let orderList = [];
  orderListQuery.message.values.map((row) =>
    orderList.push(
      Object.fromEntries(
        row.map((value, index) => [orderListQuery.message.keys[index], value])
      )
    )
  );

  let salesOrderItemFilters = [];
  orderList.forEach((purchaseOrder) => {
    salesOrderItemFilters.push([
      "Purchase Order Item",
      "parent",
      "=",
      purchaseOrder.name,
    ]);
  });

  const purchaseOrderItemsParams = new URLSearchParams({
    doctype: "Purchase Order",
    fields: JSON.stringify([
      "`tabPurchase Order Item`.`item_name`",
      "`tabPurchase Order Item`.`qty`",
      "`tabPurchase Order Item`.`uom`",
      "`tabPurchase Order Item`.`idx`",
      "`tabPurchase Order Item`.`parent`",
      "`tabPurchase Order Item`.`item_code`",
    ]),
    or_filters: JSON.stringify(salesOrderItemFilters),
  });
  await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: purchaseOrderItemsParams.toString(),
  }).then((res) => {
    res.message?.values
      .sort((item1, item2) => (item1[3] > item2[3] ? 1 : -1))
      .forEach((item) => {
        let orderId = orderList.findIndex((order) => order.name === item[4]);

        if (!orderList[orderId].items) orderList[orderId].items = [];

        orderList[orderId].items.push({
          item_name: item[0],
          qty: item[1],
          uom: item[2],
          item_code: item[5],
        });
      });
  });

  let commentsFilters = orderList.map((purchaseOrder) => [
    "Comment",
    "reference_name",
    "=",
    purchaseOrder.name,
  ]);
  const commentParams = new URLSearchParams({
    doctype: "Comment",
    fields: JSON.stringify([
      "`tabComment`.`content`",
      "`tabComment`.`creation`",
      "`tabComment`.`reference_name`",
    ]),
    or_filters: JSON.stringify(commentsFilters),
    filters: JSON.stringify([["Comment", "comment_type", "=", "Comment"]]),
  });
  await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: commentParams.toString(),
  })
    .then((res) => {
      res.message.values?.forEach((comment) => {
        let orderId = orderList.findIndex((order) => order.name === comment[2]);

        if (!orderList[orderId].comments) orderList[orderId].comments = [];

        orderList[orderId].comments.push({
          content: comment[0],
          creation: comment[1],
        });
      });
    })
    .catch((error) => {});

  return orderList;
};

export const fetchComments = async (
  referenceNames: Array<string>,
  authContext: AuthContextType
): Promise<{ [name: string]: { content: string; creation: string } }> => {
  if (referenceNames.length === 0) return {};
  let commentsFilters = referenceNames.map((name) => [
    "Comment",
    "reference_name",
    "=",
    name,
  ]);
  let result = {};

  const commentParams = new URLSearchParams({
    doctype: "Comment",
    fields: JSON.stringify([
      "`tabComment`.`content`",
      "`tabComment`.`creation`",
      "`tabComment`.`reference_name`",
    ]),
    or_filters: JSON.stringify(commentsFilters),
    filters: JSON.stringify([["Comment", "comment_type", "=", "Comment"]]),
  });
  await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: commentParams.toString(),
  })
    .then((res) => {
      res.message.values?.forEach((comment) => {
        if (!result[comment[2]]) result[comment[2]] = [];

        result[comment[2]].push({ content: comment[0], creation: comment[1] });
      });
    })
    .catch((error) => {});

  return result;
};

export const fetchAddress = async (
  address: string,
  authContext: AuthContextType
): Promise<null | {
  name: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  email: string;
  country: string;
}> => {
  if (!address) return null;
  let addressQuery = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      doctype: "Address",
      fields: JSON.stringify([
        "address_line1",
        "address_line2",
        "address_title",
        "pincode",
        "city",
        "email_id",
        "country",
      ]),
      filters: JSON.stringify([["Address", "name", "=", address]]),
    }).toString(),
  });

  /* this is complicated. We have 2 ways for addresses:
  - Company
  - Street
  or
  - Company
  - Name of owner
  - Street
  */
  if (!!addressQuery.message && !!addressQuery.message.values) {
    let result = addressQuery.message.values[0];
    let country = "";
    console.log(result[6]);
    console.log(findCountryCodeByName(result[6]));
    country = findCountryCodeByName(result[6]) ?? "";

    return {
      name: result[1] ? result[0] : "",
      address1: result[1] ? result[1] : result[0],
      address2: result[2], // this is always the company
      zipCode: result[3].trim(),
      city: result[4].trim(),
      email: result[5],
      country,
    };
  } else {
    return null;
  }
};

export const fetchOrderCount = async (
  showOnlyOpenOrders: boolean,
  searchQuery: string,
  authContext: AuthContextType
): Promise<number> => {
  let filter = [];
  if (showOnlyOpenOrders) filter = [...OPEN_ORDERS_FILTER];
  else filter = [...ALL_ORDERS_FILTER];
  if (searchQuery != "")
    filter.push(["Sales Order", "customer_name", "like", `%${searchQuery}%`]);

  const salesOrderParams = new URLSearchParams({
    doctype: "Sales Order",
    fields: JSON.stringify(["count(`tabSales Order`.`name`) AS total_count"]),
    filters: JSON.stringify(filter),
  });
  let result = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: salesOrderParams.toString(),
  });
  return result.message.values[0][0];
};

export const fetchDoc = async (
  name: string,
  doctype: string,
  authContext: AuthContextType
) => {
  let query = await erpQuery({
    uri: `method/frappe.desk.form.load.getdoc?doctype=${encodeURIComponent(
      doctype
    )}&name=${encodeURIComponent(name)}`,
    method: "GET",
    authContext,
  });
  if (!query) return false;

  return {
    ...query.docs[0],
    docinfo: query.docinfo,
  };
};

export interface customerDetail {
  name: string;
  customer_details: string;
  email_id?: string;
}
export const fetchCustomerDetails = async (
  customers: Array<string>
): Promise<customerDetail[]> => {
  let filter = customers.map((customer) => ["Customer", "name", "=", customer]);
  const params = new URLSearchParams({
    doctype: "Customer",
    fields: JSON.stringify(["name", "customer_details", "email_id"]),
    or_filters: JSON.stringify(filter),
  });
  // TODO: the customerLoader should be initialized with auth data. This will probably work
  // forever, but makes the AuthProvider a little useless
  let auth: AuthType;
  if (typeof localStorage == "undefined") auth = {};
  else
    auth = JSON.parse(localStorage.getItem("auth") ?? "{}") as {
      [user: string]: { token: string; refreshToken: string; active: boolean };
    };
  let query = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
    auth,
    setAuth: () => {},
  });

  let result = [];
  let customerDetailsArray = query.message.values?.map((row) =>
    Object.fromEntries(
      row.map((value, index) => [query.message.keys[index], value])
    )
  );
  // resort according to input
  for (let index = 0; index < customers.length; index++) {
    result.push(
      customerDetailsArray.find(
        (customer) => customer.name === customers[index]
      )
    );
  }
  return result;
};

export interface itemDetail {
  name: string;
  has_batch_no: boolean;
  warehouse_title: string;
  warehouse_color: string;
  default_bom?: string;
  weight_uom: string;
  weight_per_unit: number;
  packagingItems?: { item: string; quantity: number; uom: string }[];
}

export const customerLoader = new Dataloader(fetchCustomerDetails, {
  cache: false,
});

export const fetchInvoicesOfSalesOrder = async (
  salesOrder: string,
  authContext: AuthContextType
): Promise<SalesInvoice[]> => {
  if (!salesOrder) return [];

  let query = (await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      doctype: "Sales Invoice",
      fields: JSON.stringify(["name"]),
      filters: JSON.stringify([
        ["Sales Invoice", "docstatus", "!=", "2"],
        ["Sales Invoice Item", "sales_order", "=", salesOrder],
      ]),
    }).toString(),
  })) as { message: { keys: ["name"]; values: [string][] } };

  if (!query.message.hasOwnProperty("values")) return [];

  let invoiceNames = Array.from(
    new Set(query.message.values.map((invoice) => invoice[0]))
  );

  let invoices: SalesInvoice[] = await Promise.all(
    invoiceNames.map((invoice) =>
      fetchDoc(invoice, "Sales Invoice", authContext)
    )
  );

  return invoices;
};

export const getConversionFactor = async (
  from: string,
  to: string,
  authContext: AuthContextType
) => {
  const params = new URLSearchParams({
    doctype: "UOM Conversion Factor",
    fieldname: "value",
    filters: JSON.stringify([
      ["UOM Conversion Factor", "from_uom", "=", from],
      ["UOM Conversion Factor", "to_uom", "=", to],
    ]),
  });

  const query = await erpQuery({
    uri: `method/frappe.client.get_value`,
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
  }).then(
    (res) =>
      res.json() as Promise<{
        message: {
          value: number;
        };
      }>
  );

  return query.message.value;
};

export const fetchInvoicesOfShopifyOrder = async (
  order: string,
  authContext: AuthContextType
): Promise<SalesInvoice[]> => {
  if (!order) return [];

  let query = (await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      doctype: "Sales Invoice",
      fields: JSON.stringify(["name"]),
      filters: JSON.stringify([
        ["Sales Invoice", "docstatus", "!=", "2"],
        ["Sales Invoice", "shopify_order_id", "=", order],
      ]),
    }).toString(),
  })) as { message: { keys: ["name"]; values: [string][] } };

  if (!query.message.hasOwnProperty("values")) return [];

  let invoiceNames = Array.from(
    new Set(query.message.values.map((invoice) => invoice[0]))
  );

  let invoices: SalesInvoice[] = await Promise.all(
    invoiceNames.map((invoice) =>
      fetchDoc(invoice, "Sales Invoice", authContext)
    )
  );

  return invoices;
};

export type ShipmentLabel = {
  invoice: string;
  name: string;
  carrier: "dpd" | "dhl" | "hermes" | "dachser" | "warenpost";
  label_url: string;
  shipment_id: string;
};
export const fetchShipmentLabels = async (
  invoices: Array<string>,
  authContext: AuthContextType
): Promise<ShipmentLabel[]> => {
  if (invoices.length === 0) return [];

  let query = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      doctype: "Sales Invoice",
      fields: JSON.stringify([
        "`tabSales Invoice`.`name` as invoice",
        "`tabShipmentLabel`.`name`",
        "`tabShipmentLabel`.`carrier`",
        "`tabShipmentLabel`.`label_url`",
        "`tabShipmentLabel`.`shipment_id`",
      ]),
      or_filters: JSON.stringify(
        invoices.map((invoice) => ["Sales Invoice", "name", "=", invoice])
      ),
    }).toString(),
  });

  return query.message.values
    ?.map((row) =>
      Object.fromEntries(
        row.map((value, index) => [query.message.keys[index], value])
      )
    )
    .filter((shipment) => !!shipment.shipment_id);
};

export type ReservedBatch = {
  batch_no: string;
  item_code: string;
  stock_qty: number;
};
export const fetchReservedBatchQuantities = async (
  authContext: AuthContextType
): Promise<ReservedBatch[]> => {
  const params = new URLSearchParams({
    doctype: "Sales Invoice",
    fields: JSON.stringify([
      "`tabSales Invoice Item`.`batch_no`",
      "`tabSales Invoice Item`.`item_code`",
      "`tabSales Invoice Item`.`stock_qty`",
    ]),
    filters: JSON.stringify([
      ["Sales Invoice", "status", "=", "Draft"],
      ["Sales Invoice Item", "batch_no", "!=", ""],
    ]),
  });
  let query = await erpQuery({
    uri: "method/frappe.desk.reportview.get",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params.toString(),
  });

  if (!query.message.hasOwnProperty("values")) return [];
  let batches = query.message.values?.map((row) =>
    Object.fromEntries(
      row.map((value, index) => [query.message.keys[index], value])
    )
  );
  for (let index = 0; index < batches.length; index++) {
    for (
      let offsetIndex: number = index + 1;
      offsetIndex < batches.length;
      offsetIndex++
    ) {
      if (batches[offsetIndex].batch_no === batches[index].batch_no) {
        batches.splice(offsetIndex, 1);
        offsetIndex--;
      }
    }
  }
  return batches;
};

export const saveShipmentToERP = async ({
  invoice,
  carrier,
  label_url,
  shipment_id,
  authContext,
}: {
  invoice: string;
  carrier: string;
  label_url?: string;
  shipment_id: string;
  authContext: AuthContextType;
}): Promise<any> => {
  let invoiceQuery = (
    await erpQuery({
      uri: "method/frappe.desk.form.load.getdoc",
      authContext,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        doctype: "Sales Invoice",
        name: invoice,
      }).toString(),
    })
  ).docs[0];

  invoiceQuery.shipments.push({
    doctype: "ShipmentLabel",
    carrier,
    label_url,
    shipment_id,
  });

  let result = erpQuery({
    uri: "method/frappe.desk.form.save.savedocs",
    authContext,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      doc: JSON.stringify(invoiceQuery),
      action: invoiceQuery.docstatus == 1 ? "Update" : "Save",
    }).toString(),
  });

  return result;
};
